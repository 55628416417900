<template>
  <div class="question">
    <div class="header">
      <lanzhouHeader/>
    </div>

    <div class="banner">
      <img src="~/static/activity/bg2.jpg" alt="" />
    </div>
    <div class="tomcdpee">
      <a href="https://lzmz.cdpee.org.cn/#/pages/index">
        <el-button style="width: 95%; margin: 0 auto; display: block" class="submit-btn" type="primary" plain>找工作学技能、快来看看吧</el-button>
      </a>
    </div>
    <div class="content">
      <p v-if="isWrite" style="color:#f56c6c;margin-bottom: 10px;font-size:11px;">您已完成问卷，如为其他残友填写问卷，请点击<a href="javascript:;" style="margin-left: 10px;cursor: pointer;" size="mini" @click="changeUser" type="primary">代报名</a></p>
      <el-form ref="ruleForm" :model="params" :show-message='false'>
        <ul class="list">
          <li name="phone">
            <div class="box">
              <div class="name"><em>*</em>本人或联系人手机号：</div>
              <div>
                <el-form-item required prop="phone">
                  <el-input v-model="params.phone" oninput="value=value.replace(/\D/g,'').replace(/\s+/g,'')" :disabled='isWrite && params.phone != ""' placeholder="请输入本人或联系人手机号" @change="phoneChange" maxlength="11"></el-input>
                </el-form-item>
              </div>
            </div>
          </li>
          <li v-if="!isLogin">
            <div class="box" name="authCode">
              <div class="name"><em>*</em>手机验证码：</div>
              <div class="row verify-row">
                <el-form-item prop="authCode" required class="authCode-input">
                  <el-input v-model="params.authCode" oninput="value=value.replace(/\D/g,'').replace(/\s+/g,'')" maxlength="6" placeholder="请输入验证码" @change="login"></el-input>
                </el-form-item>
                <el-button style="width=20%;" type="primary" :disabled="getCodeBtnDisable" @click="sendMsg">{{ btntxt }}</el-button>
              </div>
              <p style="font-size: 10px; color: #ff9600;">
                验证码五分钟内有效，五分钟内您可以重复使用验证码。
              </p>
              <p v-if="!isDcAuth2" style="font-size: 10px; color: #f56c6c">
                抱歉，您不是残疾人无法填写此问卷。
              </p>
            </div>
          </li>
          <li name="writePerson">
            <div class="box">
              <div class="name"><em>*</em>姓名：</div>
              <div>
                <el-form-item required prop="writePerson">
                  <el-input v-model="params.writePerson" placeholder="请输入联系人姓名" :readonly="nameReadonly" :disabled="nameReadonly"></el-input>
                </el-form-item>
              </div>
            </div>
          </li>
          <li name="idNumber">
            <div class="box">
              <div class="name"><em>*</em>身份证号：</div>
              <div>
                <el-form-item required prop="idNumber">
                  <el-input v-model="params.idNumber" oninput="value=value.replace(/\s+/g,'')" placeholder="请输入身份证号" @change="queryDcAuth" maxlength="18" :readonly="idReadonly" :disabled="idReadonly"></el-input>
                </el-form-item>
                <p v-if="!isDcAuth" style="font-size: 10px; color: #f56c6c">
                  抱歉，您不是残疾人无法填写此问卷。
                </p>
                <p v-if="isNotLimit">本问卷允许就业年龄段残疾人填写，年龄段范围（男16-59周岁，女16-54周岁）</p>
              </div>
            </div>
          </li>
          <li name="provinceid">
            <div class="box">
              <div class="name"><em>*</em>所在社区：</div>
              <div class="row12">
                <el-form-item required prop="provinceid" class="provinceid-select">
                  <ThreeLink width='100%' aria-label="下拉列表框" class="three-link-half" codeType="provinceid" placeholder="请选择" classed="province-popper" expandTrigger="hover" :codeLevel="3" size="large" @setMValue="setProvinceValue($event, 'sq')" :defaultValue="[
                  params.provinceid,
                  params.cityid,
                  params.threeCityid,
                ]"></ThreeLink>
                </el-form-item>
                <el-input size="large" v-model="params.address" placeholder="请输入详细街道地址"></el-input>
              </div>
            </div>
          </li>
          <li name="willWork">
            <div class="box">
              <div class="name"><em>*</em>是否愿意参加工作：</div>
              <div>
                <el-form-item required prop="willWork">
                  <el-radio-group v-model="params.willWork">
                    <el-radio label="1"> 是 </el-radio>
                    <el-radio label="0"> 否 </el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </li>
          <template v-if="params.willWork == 1">
            <li name="selfCare">
              <div class="box">
                <div class="name"><em>*</em>是否具备生活自理能力：</div>
                <div>
                  <el-form-item required prop="selfCare">
                    <el-radio-group v-model="params.selfCare">
                      <el-radio label="1"> 是 </el-radio>
                      <el-radio label="0"> 否 </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="jobType">
              <div class="box">
                <div class="name"><em>*</em>期望的工作性质：</div>
                <div>
                  <el-form-item required prop="jobType">
                    <el-radio-group v-model="params.jobType">
                      <el-radio v-for="type in jobTypes" :label="type.value" :key="type.value">
                        {{ type.label }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="willTrain">
              <div class="box">
                <div class="name"><em>*</em>是否愿意参加就业服务或培训，实现就业：</div>
                <div>
                  <el-form-item required prop="willTrain">
                    <el-radio-group v-model="params.willTrain">
                      <el-radio label="1"> 是 </el-radio>
                      <el-radio label="0"> 否 </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="jobStatus">
              <div class="box">
                <div class="name"><em>*</em>当前在职状态：</div>
                <div>
                  <el-form-item required prop="jobStatus">
                    <ThreeLink aria-label="下拉列表框" class="three-link" codeType="job_invia" placeholder="请选择" expandTrigger="hover" :codeLevel="3" size="large" @setMValue="setScasValue($event, 'jobStatus')" :defaultValue="[params.jobStatus]"></ThreeLink>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="workProvinceid">
              <div class="box">
                <div class="name"><em>*</em>您愿意在哪个地区就业：</div>
                <div>
                  <el-form-item required prop="workProvinceid">
                    <ThreeLink aria-label="下拉列表框" class="three-link" codeType="provinceid" placeholder="请选择" classed="province-popper" expandTrigger="hover" :codeLevel="3" size="large" @setMValue="setProvinceValue($event, 'gzd')" :defaultValue="[
                    params.workProvinceid,
                    params.workCityid,
                    params.workThreeCityid,
                  ]"></ThreeLink>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="jobEdu">
              <div class="box">
                <div class="name"><em>*</em>目前最高学历：</div>
                <div>
                  <el-form-item required prop="jobEdu">
                    <ThreeLink :codeType="'user_edu'" placeholder="请选择" class="width100" @setMValue="setScasValue($event, 'jobEdu')" :defaultValue="[params.jobEdu]"></ThreeLink>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="jobSalary">
              <div class="box">
                <div class="name"><em>*</em>期望薪资</div>
                <div>
                  <el-form-item required prop="jobSalary">
                    <ThreeLink :codeType="'user_salary'" placeholder="请选择" class="width100" @setMValue="setScasValue($event, 'jobSalary')" :defaultValue="[params.jobSalary]"></ThreeLink>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="jobTop">
              <div class="box">
                <div class="name"><em>*</em>期望职位：</div>
                <div>
                  <el-form-item required prop="jobTop">
                    <ThreeLink codeType="job1" codeLevel="3" placeholder="请选择" class="width100" @setMValue="
                    setProvinceValue($event, 'zw', ['jobTop', 'jobNext','jobPost'])
                  " :defaultValue="[params.jobTop, params.jobNext, params.jobPost]"></ThreeLink>
                  </el-form-item>
                </div>
              </div>
            </li>
            <li name="beforeJob">
              <div class="box">
                <div class="name"><em>*</em>从事过什么职业：</div>
                <div>
                  <el-form-item required prop="beforeJob">
                    <el-input type="textarea" autosize placeholder="请输入内容" v-model="params.beforeJob" :autosize="{ minRows: 2, maxRows: 4 }">
                    </el-input>
                  </el-form-item>
                  <p class="tips" style="color: #ff9600; font-size: 10px">
                    多个职业用请用,分割
                  </p>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </el-form>
      <div class="prot">
        <el-checkbox v-model="isprotocol">同意自动注册本平台账号，并同意接受
          <router-link target="_blank" to="/protocol/userServe/">用户服务协议</router-link>
          和
          <router-link target="_blank" to="/protocol/agreement/">隐私政策</router-link>。 如您有求职意向，会自动加入平台人才库。
        </el-checkbox>
      </div>
      <div class="btn">
        <el-button type="primary" :disabled='islock' @click="submit">立即提交</el-button>
      </div>
    </div>

    <div class="footer">
      <lanzhou-footer/>
    </div>
  </div>
</template>

<script>
import ThreeLink from '@/components/selectComponents/ThreeLink'
import headerCom from '@/components/public/newHeader'
import footerCom from '@/components/public/Footer'
import lanzhouFooter from "@/components/shanxi-cdpe/footer";
import lanzhouHeader from "@/components/lanzhou-cdpe/header";
import { EventBus } from '@/plugins/utils/event-bus.js'
let isChange = false // 是否切换用户的标识
export default {
  name: 'question',
  components: {
    ThreeLink,
    lanzhouHeader,
    lanzhouFooter
  },
  inject: ['reload'],
  data() {
    return {
      detail: [],
      list: [],
      questionnaireList: [],
      params: {
        address: '',
        beforeJob: '',
        cityid: '',
        createBy: '',
        createTime: '',
        dcNumber: '',
        id: '',
        idNumber: '',
        isHaveUser: '',
        jobEdu: '',
        jobNext: '',
        jobPost: '',
        jobSalary: '',
        jobStatus: '',
        jobTop: '',
        jobType: '',
        phone: '',
        provinceid: '',
        remark: '',
        resumeExpectId: '',
        selfCare: '',
        threeCityid: '',
        updateBy: '',
        updateTime: '',
        willTrain: '',
        willWork: '',
        workAddress: '',
        workCityid: '',
        workProvinceid: '',
        workThreeCityid: '',
        writePerson: '',
        authCode: '',
        isMine: '0',
      },
      authCode: '',
      isLogin: false,
      jobTypes: [],
      btntxt: '获取验证码',
      getCodeBtnDisable: false,
      time: 180,
      isHide: false,
      defaultAdd: [],
      isprotocol: false,
      isDcAuth: true,
      isDcAuth2: true,
      isauthCode: true,
      nameReadonly: false,
      idReadonly: false,
      loginUserName: '',
      islock: false,
      isWrite: false,
      rules: {
        provinceid: [
          { required: true, message: '请输入活动名称', trigger: 'change' },
        ],
        phone: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
      },
      isNotLimit: false,
    }
  },
  created() {
    this.init()
    this.$api
      .selectListApi({
        dictCode: 'job_type',
      })
      .then((res) => {
        res = res.data
        if (res.success) {
          this.jobTypes = res.data || []
        }
      })
    if (this.getLogin()) {
      this.isLogin = true
      this.params.phone = this.getLogin().linkphone || ''
      this.params.isMine = 1
      this.isprotocol = true
      this.getUserInfo()
    }
  },
  watch: {
    'params.beforeJob'(val) {
      this.params.beforeJob = val.replace(
        /[^\a-\z\A-\Z0-9\u4E00-\u9FA5,，]/g,
        ''
      )
    },
  },
  methods: {
    async changeUser() {
      // 切换用户将之前填写的恢复默认
      this.isWrite = false
      this.nameReadonly = false
      this.idReadonly = false
      this.nameReadonly = false
      this.params.writePerson = ''
      // this.params.authCode = ''
      // this.params.phone = ''
      this.params.idNumber = ''
      this.params.dcNumber = ''
      this.isWrite = false
      this.islock = false
      this.isChange = true
      // 退出登录
      //  let res = await this.$api.outLoginApi();
      //       this.$store.commit("SET_USER_INFO", 1);
      //       this.$store.commit("SET_TOKEN", "");
      //       this.$store.commit("GET_USER", "");
      //       localStorage.removeItem("access_token");
      //       localStorage.removeItem("userInfo");
      //       localStorage.removeItem("userType");
      //        setTimeout(() => {
      //           this.reload();
      //         }, 50)
      // 退出登录
    },
    getLogin() {
      var userInfo =
        this.$store.state.userType ||
        JSON.parse(localStorage.getItem('userType'))
      return userInfo || null
    },
    // 查询身份证号是不是残疾人身份证号
    queryDcAuth() {

      var reg = /^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/
      if (!reg.test(this.params.idNumber)) {
        this.$message.error({
          message: '请输入有效身份证号',
        })
        return false
      }
      this.$api
        .disableInfoCheck({
          // idCard: this.$sm2Encrypt(this.params.idNumber),
          idCard: this.params.idNumber,
        })
        .then((res) => {
          if (!res.data) {
            this.isDcAuth = false
          } else {
            res = res.data
            this.isDcAuth = true
          }
        })
    },
    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        return true // 移动端
      } else {
        return false // PC端
      }
    },
    submit() {
      this.$refs.ruleForm.validate()
      const { phone } = this.params
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.$message.error({
          message: '请输入有效手机号',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=phone]').offsetTop - 150
        return false
      }
      if (!this.isDcAuth || !this.isDcAuth2) {
        this.$message.error({
          message: '抱歉，您不是残疾人无法填写此问卷',
        })
        return false
      }
      if (this.isNotLimit) {
        this.$message.error({
          message:
            '本问卷允许就业年龄段残疾人填写，年龄段范围（男16-59周岁，女16-54周岁）',
        })
        return false
      }
      const params = this.params
      if (!params.phone) {
        this.$message.error({
          message: '请输入本人或联系人手机号',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=phone]').offsetTop - 150
        return false
      }
      if (!this.isLogin && !params.authCode) {
        this.$message.error({
          message: '请输入验证码',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=authCode]').offsetTop - 150
        return false
      }
      if (!params.writePerson) {
        this.$message.error({
          message: '请输入联系人姓名',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=writePerson]').offsetTop - 150
        return false
      }
      if (!params.idNumber) {
        this.$message.error({
          message: '请输入身份证号',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=idNumber]').offsetTop - 150
        return false
      }
      if (!params.provinceid) {
        this.$message.error({
          message: '请选择所在社区',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=provinceid]').offsetTop - 150
        return false
      }
      if (!params.willWork) {
        this.$message.error({
          message: '请选择是否愿意参加工作',
        })
        document.documentElement.scrollTop =
          document.querySelector('[name=willWork]').offsetTop - 150
        return false
      }
      if (params.willWork == 1) {
        if (!params.selfCare) {
          this.$message.error({
            message: '请选择是否具备生活自理能力',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=selfCare]').offsetTop - 150
          return false
        }
        if (!params.jobType) {
          this.$message.error({
            message: '请选择期望的工作性质',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=jobType]').offsetTop - 150
          return false
        }
        if (!params.willTrain) {
          this.$message.error({
            message: '请选择是否愿意参加就业服务或培训，实现就业',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=willTrain]').offsetTop - 150
          return false
        }
        if (!params.jobStatus) {
          this.$message.error({
            message: '请选择当前在职状态',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=jobStatus]').offsetTop - 150
          return false
        }
        if (!params.workProvinceid) {
          this.$message.error({
            message: '请选择您愿意在哪个地区就业',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=workProvinceid]').offsetTop - 150
          return false
        }
        if (!params.jobEdu) {
          this.$message.error({
            message: '请选择您目前最高学历',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=jobEdu]').offsetTop - 150
          return false
        }
        if (!params.jobSalary) {
          this.$message.error({
            message: '请选择期望薪资',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=jobSalary]').offsetTop - 150
          return false
        }
        if (!params.jobTop) {
          this.$message.error({
            message: '请选择期望职位',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=jobTop]').offsetTop - 150
          return false
        }
        if (!params.beforeJob) {
          this.$message.error({
            message: '请输入您从事过什么职业',
          })
          document.documentElement.scrollTop =
            document.querySelector('[name=beforeJob]').offsetTop - 150
          return false
        }
      }
      if (!this.isprotocol) {
        this.$message.error({
          message: '请先阅读并勾选用户服务协议和隐私政策',
        })
        return
      }
      this.params.beforeJob = this.params.beforeJob.replace(/[，]/g, ',')
      if (this.islock) return false
      this.islock = true
      console.log(
        this.$sm2Encrypt(this.params.idNumber),
        '---',
        this.params.idNumber
      )
      this.$api
        .questionnaireSurvey({
          ...this.params,
          idNumber: this.$sm2Encrypt(this.params.idNumber),
          site: this.isMobile() ? 'm' : 'pc',
          isMine: !this.isChange ? this.params.isMine : 0,
          // dcNumber: this.$sm2Encrypt(this.params.dcNumber),
        })
        .then((res) => {
          res = res.data
          this.islock = false
          if (res.success) {
            this.$router.push({
              path: '/actionPlan/success',
              query: {
                cityid: this.params.workCityid,
                threeCityid: this.params.workThreeCityid,
                jobOrCompanyName: this.params.beforeJob,
                jobSalary: this.params.jobSalary,
                jobTop: this.params.jobTop,
                jobNext: this.params.jobNext,
                jobPost: this.params.jobPost,
                jobType: this.params.jobType,
                edu: this.params.jobEdu,
              },
            })
          } else {
            this.$message.error({
              message: res.msg || '提交失败',
            })
          }
        })
        .catch((err) => {
          this.islock = false
        })
    },
    // 发送验证码
    sendMsg() {
      // if (this.getLogin()) return false // 当已经登录，发送验证码无效
      const { phone } = this.params
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.$message.error({
          message: '请输入有效手机号',
        })
        return false
      }
      this.getCodeBtnDisable = true
      this.btntxt = '发送中...'
      this.$axios
        .post('/api/app-jycy-phoneverificationcode/phoneVerification/' + phone)
        .then((res) => {
          if (res.data.success) {
            this.$message.success({
              message: '验证码已经发送至您的手机，请注意接收',
            })
            this.time = 180
            this.timer()
          } else {
            this.getCodeBtnDisable = false
            this.btntxt = '获取验证码'
            this.$message.error({
              message: res.data.msg || '发送失败',
            })
          }
        })
      this.handleClose()
    },
    login() {
      if (this.getLogin()) return false // 当已经登录，发送验证码无效
      if (!this.params.authCode) {
        return false
      }
      this.$api
        .getLoginApi({
          userName: this.params.phone,
          password: this.params.authCode,
          loginType: '1',
          touristType: '1',
        })
        .then((res) => {
          res = res.data
          if (res.success) {
            let user = res.data
            //残疾人个人信息
            this.$store.commit('SET_USER_INFO', user)
            //存储access_token
            this.$store.commit('SET_TOKEN', user.token)
            //存储refresh_token
            this.$store.commit('SET_REFRESH_TOKEN', user.token)
            EventBus.$emit('userInfo', user)
            this.isprotocol = true
            // this.$message.success({
            //   message: '登录成功！',
            // })
            this.params.isMine = 1
            if (user.userType == 2) {
              this.isDcAuth2 = false
              this.isDcAuth = false
            } else {
              this.isDcAuth2 = true
              this.isDcAuth = true
            }
            if (user.userType != 2) {
              this.getUserInfo()
            }
            this.isauthCode = true
            this.init()
          } else {
            this.$message.error({
              message: '验证码错误',
            })
            this.isauthCode = false
          }
        })
    },
    phoneChange(val) {
      this.params.authCode = ''
      this.isauthCode = false
      this.nameReadonly && (this.params.writePerson = '')
      this.idReadonly && (this.params.idNumber = '')
      this.idReadonly = false
      this.nameReadonly = false
      this.isWrite = false
      this.islock = false
      // this.isLogin = false
      // 变更手机号后可重新发送验证码
      this.time = 0
      this.timer()
      if (this.getLogin() && this.params.phone == this.getLogin().linkphone) {
        this.isLogin = true
        this.getUserInfo()
        // this.init()
      }
    },
    getUserInfo() {
      if (this.getLogin().userType == 2) return false
      this.$api.getMyInfo().then((res) => {
        res = res.data
        if (res.success) {
          const data = res.data || {}
          this.params.writePerson = data.userName
          this.params.cityid = data.addressCityid
          this.params.threeCityid = data.addressThreeid
          this.params.provinceid = data.addressProvinceid
          this.params = {
            ...this.params,
            address: data.addressDetail || '',
            jobEdu: data.edu,
            jobSalary: data.salary,
            jobStatus: data.jobStatus,
            idNumber: data.idNumber,
            dcNumber: data.dcNumber,
          }
          this.idReadonly = data.idNumber ? true : false
          this.nameReadonly = data.userName ? true : false

          let age = parseInt(data.age)
          if (data.sex == '男') {
            // 16-59
            this.isNotLimit = age < 16 || age > 59
          }
          if (data.sex == '女') {
            // 16-54
            this.isNotLimit = age < 16 || age > 54
          }
        }
      })
    },
    handleClose() {
      this.isHide = false
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--
        this.btntxt = this.time + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '获取验证码'
        this.getCodeBtnDisable = false
      }
    },
    async init() {
      // let questionnaireId = this.$route.query.id
      // let res = await this.$api.etTrainQuestionnaire({
      //   questionnaireId: questionnaireId,
      // })
      // if (res.data.success) {
      //   this.detail = res.data.data
      //   this.list = this.detail.trainQuestionnaireMatterOutDtoList
      // }
      // 查询是否提交过表单
      this.$api.checkAgeQuestionnaire().then((res) => {
        res = res.data
        if (res.code == 500) {
          this.islock = true
          this.isWrite = true
        }
      })
    },
    setProvinceValue(val, name) {
      if (!val) return false
      if (name == 'sq') {
        this.params.provinceid = val[0]
        this.params.threeCityid = val[2]
        this.params.cityid = val[1]
      }
      if (name == 'gzd') {
        this.params.workProvinceid = val[0]
        this.params.workCityid = val[1]
        this.params.workThreeCityid = val[2]
      }
      if (name == 'zw') {
        this.params.jobTop = val[0]
        this.params.jobNext = val[1]
        this.params.jobPost = val[2]
      }
    },
    setScasValue(val, name) {
      if (!val || val.length == 0 || !val[0]) return false
      this.params[name] = val[0]
    },
  },
}
</script>
<style scoped lang="less">
.question {
  min-height: 100vh;
  background: white;
  .tomcdpee {
    display: none;
  }
  @media screen and (max-width: 980px) {
    .header,
    .footer {
      display: none;
    }
    .tomcdpee {
      display: block;
    }
  }
  img {
    width: 100%;
  }
  em {
    color: #f56c6c;
    margin-right: 4px;
  }
  .content {
    width: 840px;
    margin: 0 auto;
    padding: 48px 0;
    @media screen and (max-width: 980px) {
      width: 95%;
      box-sizing: border-box;
      padding: 18px 0 28px;
    }
    /deep/ .el-form-item {
      margin-bottom: 0px;
    }
    /deep/ .el-form-item.is-error .el-input__inner {
      border-color: #f56c6c !important;
    }
    /deep/ .el-form-item.is-error .el-textarea__inner {
      border-color: #f56c6c !important;
    }
    .three-link {
      width: 100%;
    }
    .three-link-half {
      width: 100%;
      padding-right: 10px;
      margin-top: -1px;
    }
    .provinceid-select {
      width: 50%;
      @media screen and (max-width: 980px) {
        width: 100%;
      }
    }
    .verify-row {
      .authCode-input {
        width: 80%;
        padding-right: 10px;
        @media screen and (max-width: 980px) {
          width: 65%;
        }
      }
      .el-button {
        width: 20%;
        @media screen and (max-width: 980px) {
          width: 35%;
        }
      }
    }
    .title {
      margin-left: 24px;
      font-size: 24px;
      margin-bottom: 22px;
    }
    a {
      color: #28a46d !important;
    }
    .list {
      padding: 30px 24px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      @media screen and (max-width: 980px) {
        padding: 15px 12px;
      }
      li {
        .box {
          padding-bottom: 48px;
          @media screen and (max-width: 980px) {
            padding-bottom: 20px;
          }

          .name {
            color: #66716c;
            margin-bottom: 20px;
            @media screen and (max-width: 980px) {
              margin-bottom: 10px;
            }
          }
          .row {
            display: flex;
            align-items: center;
          }
          .row12 {
            display: flex;
            align-items: center;
            @media screen and (max-width: 980px) {
              display: block;
              .three-link-half {
                width: 100%;
                padding-right: 0;
                margin-bottom: 10px;
              }
            }
          }
        }

        .box:last-child {
          margin-bottom: 0;
        }
      }
    }
    /deep/ .el-radio {
      margin-right: 15px;
      .el-radio__label {
        padding-left: 5px;
      }
    }
    .prot {
      margin-top: 28px;
      /deep/ .el-checkbox__label {
        color: #66716c !important;
      }
      @media screen and (max-width: 980px) {
        /deep/ .el-checkbox {
          display: flex;
          overflow: hidden;
          .el-checkbox__label {
            white-space: pre-wrap;
            margin-top: -3px;
            font-size: 10px;
          }
        }
      }
    }
    .btn {
      text-align: center;
      margin-top: 28px;
      @media screen and (max-width: 980px) {
        margin-top: 28px;
      }
      .el-button {
        width: 336px;
      }
    }
  }
}
</style>
<style lang='less'>
@rem: 750/10rem;
@media screen and (max-width: 980px) {
  #app,
  .main {
    width: 100%;
    min-width: 100%;
  }
  .el-cascader-panel {
    overflow: scroll;
    .el-scrollbar {
      min-width: 160 / @rem;
    }
  }
  .province-popper {
    max-width: 95% !important;
    .el-scrollbar__view {
      width: 120px;
    }
  }
}
</style>
